@import "lib";
header {
  .logo {
    margin-top:28px;  
    img.fullerlife {
      margin-left:10px;   
    }    
  }      
}



.outerwrap {
   header {
      background:#282828;
      .logo {
         margin:0;
         padding:10px 0 0px 0;
         float:left;
         margin-top:25px;
         .microsoft {
           margin-right:10px;
           padding-right:20px;          
           border-right:1px solid #fff;
         }     
      } 
      .notificationsandprofile {
         float:right;  
         padding:10px 0 0px 0;
         position: relative;
         div.reminder {
            float:left;
            width:50px;
            margin-top:15px;
            cursor: pointer;
            .notificationswrap {
               display: none;    
               position: absolute;
               width:440px;
               padding-top:25px;
               z-index: 1000;
                 background:url(../images/notification-toparrow.png);
            background-repeat: no-repeat;
            background-position: 96% 5%;
               left:-395px;
               .notification {
                  background:#f2f2f2;  
                  overflow: hidden;
                  padding-bottom: 10px;
                  height:280px;
                  .notificationtoggle {
                    text-align: right;
                    margin:5px;
                  }
                  .notificationtasks {
                     ul {
                       list-style:none;
                       float:right;
                       position: relative;
                       li {
                          float:left;
                          padding:15px 0px;
                          a {
                             color:#bfc933;   
                             border-right: 1px solid #a8a8a8;
                             padding:0 6px;
                          }
                       }
                       li.settings {
                         background:url(../images/icon-notification-settings.png);
                         background-repeat: no-repeat;
                         position: relative;
                         top:16px;
                         left:5px;
                         width:30px;
                         height:15px;
                         text-indent: -999999px;
                         
                         a {
                           display: block;
                            width:16px;
                            height:15px;
                            border:none;
                         }
                       }
                     } 
                  }
                  .notificationfeeds {
                     clear:both; 
                     ul {
                       list-style: none;
                       margin:0;
                       padding:0;
                       li {
                         background:#fff;
                         margin:10px 10px;
                         padding:5px;
                         span.icon {
                           margin-right: 10px;   
                         }
                       }
                     }
                  }
                  
               }
            }    
         } 
         div.profile {
            float:right;
            min-width:140px;
            position: relative;
            cursor: pointer;
            ul {
              position: absolute;   
              width:156px;
              list-style: none;
               top:65px;
               left:12px;
               padding:8px 10px;
               z-index: 100;
               background:url(../images/cities-list-up-arrow.png);
               background-position: 74px 0;
               background-repeat: no-repeat;
               display:none;
               -webkit-animation-duration: 1s;
                -webkit-animation-delay: 0.1s;
                -moz-animation-duration: 1s;
                -moz-animation-delay: 0.1s;
                -ms-animation-duration: 1s;
                -ms-animation-delay: 0.1s;
                -o-animation-duration: 1s;
                -o-animation-delay: 0.1s;
                animation-duration: 1s;
                animation-delay: 0.1s;
               li {
                background:#fff;  
                padding:2px 2px 2px 40px; 
                margin:0 3px;
                font-size: 12px;
                font-weight: normal;             
                background:#fff url(../images/Logout_Icon.png);
                background-repeat: no-repeat;
                background-position: 13px 9px;
                border-bottom:1px solid #e8e8e8;
                a {
                   display: block;
                    padding:8px 0;                   
                    color:#49366f;
                    
                }   
               
                
               } 
               li.profilelink {
                background:#fff url(../images/Profile_Icon.png);
                background-repeat: no-repeat;
                background-position: 13px 10px;  
               }
               li.privacy {
                background:#fff url(../images/Privacy_Icon.png);
                background-repeat: no-repeat;
                background-position: 13px 10px;  
               }
            }    
            span.name {
              display: block;
              float:left;
              color:#fff;
              text-align: right;
              margin-top:15px;
              margin-right:15px;
            }    
            .profileimage {
               float: right;
               margin-right: 10px;
               overflow: hidden;
               border-radius: 50%;
               width: 60px;
               height: 60px;  
               position: relative;              
               border:2px solid #fff;
               img {
                 display: inline;
                 margin: 0 auto;
                 height: 100%;   
               } 
               
            }    
         }     
              
      }  
      nav {
        clear:both;
        text-align:center;
        position: relative;
        margin-bottom: 0;
        padding-bottom: 0;      
        ul {
          display: inline-block;
          list-style:none;  
          margin-bottom: 0;
          position: relative;
          top:4px;
          li {
            float:left; 
            padding:0px 0px 10px 0px;
            margin:0 20px;
            color:#fff;
            font-size:13px; 
            border-bottom: 6px solid #282828;
            position: relative;
            a {
              color:#fff;
              text-transform: uppercase;
            }    
            a:hover {
              text-decoration: none;
            } 
            span {
              .fa {
                 font-size: 5px;   
                 position: relative;
                 top:-10px;
                 left:0px;
              } 
            }  
            span.green {
               .fa {
                 color:#a5cd38;   
               } 
            }
            span.red {
               .fa {
                 color:#fe0100;   
               } 
            }
          } 
          li.highlight {
            border-bottom: 6px solid #5a4484;
             
          } 
           
        }    
      }      
   }   
}    

.information {
    margin-top:20px;
    .informationwrap {      
       margin:30px 40px;
    }     
    .note {        
        width:430px;
        float:right;
        padding:0 25px 25px 25px;
        margin-top:20px;
        background:#fff url(../images/box-rightbg.png);
        background-position: 370px 5px;
        background-repeat: no-repeat;
        h2 {
          font-size:24px;
          font-weight: 900;
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          line-height: 30px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #e4e4e4;
        }  
        h3 {
          font-size:14px;
          font-weight: 900;
          font-family: 'Open Sans', sans-serif;
          text-transform: none;
          color:#5f4595;
          line-height: 30px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #e4e4e4;
        }  
        ul {
          list-style: none;
          padding: 0;
          margin: 0;   
             li {
              padding:5px 0 5px 12px;
              margin:0;  
              background:url(../images/bullet.png);
              background-repeat: no-repeat;
              background-position: 0 13px;
            }
            
        }    
    }
    #note_email_error,
    #note_password_1 {
        width:auto;
        padding:0 5px 5px 5px;
        float:none;
        margin-top:5px;
    }   
    .winnernote {
       h2 {
          font-size:44px;
          font-weight: 900; 
          line-height: 44px;
      }   
    }
    .login {
        background:#fff url(../images/box-rightbg.png);
        background-position: 240px 5px;
        background-repeat: no-repeat;
        width:300px;
        float:right;
        padding:0 20px 20px 20px;
        margin-top:20px;
        h2 {
          font-size:24px;
          font-weight: 800;
          font-family: 'Open Sans', sans-serif;
          text-transform: uppercase;
          line-height: 30px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #e4e4e4;  
          text-align: center;
        } 
        
         h2.lf {
           text-align:left;  
         }     
        h3 {
          font-size:16px;
          font-weight: bolder;
          font-family: 'Open Sans', sans-serif;          
          text-align: left;
          color:#000;
        } 
        h3.slogan {
          font-size:33px;
          font-weight: bolder;
          font-family: 'Open Sans', sans-serif;          
          text-align: left;
          color:#5f4595;
          border:none;
        } 
        p {
          margin:20px 20px 20px 0;   
        }   
        ol {
          padding:3px 0 3px 15px;
          margin:0;
          li {
            padding:3px 0 3px 0;   
          }      
        }    
        .form-group {
          margin-top:10px;    
          label {
          font-size:14px;         
          } 
          .form-control {
            height:40px;   
          } 
          p.forgotpassword {
            text-align: right;
            padding-bottom: 20px;
            a {
              color:#454545;   
            }    
          }      
        } 
        .form-group.buttons {
          text-align:center;
          margin-bottom: 20px;
          .btn-default {
             width:150px;
             height:40px;
          } 
          .btn {
              font-weight:700;
              font-size:19px;
           }            
        }    
         .form-group.buttons.forgotpassword {         
          margin-top: 0px;
          p {
            text-align:left;   
          }  
          ol {
            text-align:left;   
          }      
        }    
        .form-group.buttons.registration {         
          margin-top: 0px;
          p {
            text-align:left;   
          }  
          ol {
            text-align:left;   
          }      
        }  
        .form-group.notamember {         
          margin-top: 0px;
          p {
            text-align:center;   
          } 
        }    
          
         
    } 
    .countdown {    
      margin-left:120px;
      div.countdownwrap {
         color:#fff;
         font-size:56px;
         font-family: 'Open Sans Condensed', sans-serif;  
         float:left;
         width:75px;
         /*background:url(../images/counter-shadow.png);*/
         background-repeat: no-repeat;
         background-position: bottom;
         padding-bottom: 40px;
        
              span.countdownname {
                display:block; 
                font-size:15px;
                text-transform: uppercase;
                margin:0 8px;;
              }    
      }      
       
    }  
}    

.primarycontent,
.popcontent .content {
   h1 {
     padding-top:80px;
     color:#fff;
     font-size:40px;
     font-weight: 600;
     text-align:center;
     text-transform: uppercase;
    
   }   
   .content {
      color:#000;
      background:#fff;
      padding:20px;
      margin-bottom: 40px;
      margin-top: 40px;
      height:440px;
      h2 {
        font-size:37px;
        font-weight: 800;    
        text-transform: uppercase;
         margin-left:20px;
        span {
           border-bottom:1px solid #e4e4e4;   
        }    
      }
      ol {
       margin:20px 0 0 0;
       padding-left:20px;
       li {
         margin:4px 0 4px 5px;   
       }   
      }      
   }   
}   

.appcontent {
   margin-top:15px;    
   h1 {
     font-size:35px;
     font-weight: 800;
     color:#fff;
     text-transform: uppercase;
     float:left;
     margin-bottom: 35px;
     position: relative;
     span {
       display: inline-block;
       margin-right:10px;
       position: relative;
       top:-2px;
     } 
     a {
       color:#fff;   
     }     
   } 
   .days {
       margin-top:20px;  
       float:right;
      ul {
        list-style:none;
        padding:0;
        margin:0;
        float:right;
        li {
          padding:8px 16px;
          margin:0;
          float:left;
          width:40px;
          height:40px;
          margin-right:3px;
          background:url(../images/days-bg.png);
          background-repeat: no-repeat;
          background-position: center;
          font-size:17px;
          a {
            color:#000;   
          }      
        }    
      }    
       li.monday,
       li.wednesday{
          padding:8px 13px;
       }
      li.active {         
          background:url(../images/days-bg-hi.png);
          background-repeat: no-repeat;
          background-position: center;          
          a {
            color:#fff;   
          }      
        }    
   } 
  
   .weeks {
     float:left;
     width:150px;
     margin-top:20px;
     margin-left:5%;
     text-transform: uppercase;
     .bxsliderweek {
       color:#fff;   
       font-size:15px;
       list-style:none;  
       display: none;
     } 
     a.previous {
        background:url(../images/button-previous.png);
        background-repeat: no-repeat;
        width:19px;
        height:27px;
        display:inline-block;
        text-indent: -999999px;
     }   
     a.next {
        background:url(../images/button-next.png);
        background-repeat: no-repeat;
        width:19px;
        height:27px;
        display:inline-block;
        text-indent: -999999px;
     }  
     span {
       color:#fff;
       font-size:16px;
       display: inline-block;
       margin:8px 15px 0 15px;
       position: relative;
       top:3px;
     }     
   } 
   .cities {
     float:right;
     position: relative;
     margin-right:10px;
     margin-top:16px;
    
   overflow: hidden;
    /* h3 {
        font-size: 15px;
        font-weight: bold;
        color:#fff;
        text-transform: uppercase;
        margin:30px 0 0 0;
        background:url(../images/cities-down-arrow.png);
        background-repeat: no-repeat;
        background-position: 0 3px;
        padding-left:25px;
        cursor: pointer;
     }  
     ul {
       position: absolute;      
       width:156px;
       list-style: none;
       top:55px;
       left:-12px;
       padding:8px 10px;
       z-index: 100;
       background:url(../images/cities-list-up-arrow.png);
       background-position: 14px 0;
       background-repeat: no-repeat;
       display:none;
       li {
        background:#fff;  
        padding:2px 2px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;   
        a {
           display: block;
            padding:8px 0;
            border-bottom: 1px solid #e8e8e8;
            color:#49366f;
        }   
        a:hover {
           display: block;
            padding:8px 0;
            border-bottom: 1px solid #e8e8e8;
            color:#fff;
            background:#6b4bad;
        }   
        
       }   
     }*/
     select {
       -webkit-appearance: button;
       -webkit-border-radius: 2px;
       -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
       -webkit-padding-end: 20px;
       -webkit-padding-start: 2px;
       -webkit-user-select: none;
       background-image: url(http://i62.tinypic.com/15xvbd5.png), -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
       background-position: 97% center;
       background-repeat: no-repeat;
       border: 1px solid #AAA;
       color: #555;
       font-size: inherit;
       margin: 0px;
       overflow: hidden;
       padding: 5px 10px;
       text-overflow: ellipsis;
       white-space: nowrap;
       width: 200px;
     }     
     
   }   
   
   .taskrow {
       .col-md-5 {
          width:43.6666666%; 
          margin-left:70px;
       }   
       
       .col-md-5.taskscol {
          width:55%; 
          
       }   
   }   
   #taskcomplete {
      -webkit-animation-duration: 1s;
        -webkit-animation-delay: 0.5s;
        -moz-animation-duration: 1s;
        -moz-animation-delay: 0.5s;
        -ms-animation-duration: 1s;
        -ms-animation-delay: 0.5s;
        -o-animation-duration: 1s;
        -o-animation-delay: 0.5s;
        animation-duration: 1s;
        animation-delay: 0.5s;  
   }   
   .todaystask {
    background:#fff;  
    padding:0px;
    overflow:hidden;
    margin-top:50px; 
    position: relative;
    
    .col-md-6:first-child {
        background:@boxcolorgrey;  
          
    }
        .taskdes {
          min-height: 220px;  
          padding-right:20px;
          h2 {
            font-weight: 800;
            font-size: 30px;
            text-transform: uppercase;
            padding-bottom: 20px;
            border-bottom: 1px solid #e5e5e5;
          }   
          h3 {
            font-weight: 600;
            font-size: 25px;
            text-transform: uppercase;
            padding-bottom: 0px;           
          }  
          h4 {
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            padding-bottom: 0px;           
          }   
        }    
   } 
   #quizcomplete .score { margin:auto;}
   .score {
     background:#fff;  
     padding:14px 25px;
     overflow:hidden;
     min-height: 267px; 
     width:304px;
     margin-left:10%;
     h3 {
       font-size:20px;
       font-weight: 600;
       text-align: center;
       text-transform: uppercase;
       padding-bottom: 15px;
       border-bottom: 1px solid #f7f7f7;
     } 
     .points {
           p { 
           font-weight: 800;
           font-size: 90px;
           text-transform: uppercase;
           text-align: center;
           margin:40px 0 0 0;
           padding:0;
           line-height: 25px;
           span.glyphicon {
              font-size: 15px; 
              font-weight: 300;
              color:#22cc4a;
              position: relative;
              top:-20px;
           }            
          }   
           p.totalpoints {
             font-weight: 600;
             font-size: 21px;   
             margin:5px 0 0 0;
           }   
       
     }
     .pointsweeklystatus {
         margin-top:20px;
        .thisweek,
        .potentialpoints {
          float:left;
          width:55%;
          text-align:center;
          border-right:1px solid #ccc;
          p {
            font-size: 11px;   
          }  
          p.total {
            font-size: 27px;   
            line-height: 11px;
          } 
        } 
        .potentialpoints {
            width:45%;
            border:none;
        }        
     }      
   } 
   
   .fiterchoicewrap .tasks {
     padding-top:0;   
   }   
   
   .tasks,
   .quizzes {
     overflow: hidden;  
     text-align: center;
     padding-top:50px;
     padding-bottom:50px;   
     ul {
       list-style:none;   
       li {
        float:left;
        width:25%;              
       min-width: 140px;
         cursor: pointer;
        position: relative;  
        .task,.quiz {
            background:#fff; 
             margin-bottom: 0px;
             box-shadow:0 0 5px 0 rgba(0,0,0,.5);
            
             padding:10px;  
             position: relative;
             -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1); 
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1); 
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1);
            transition: all 200ms ease-in;
            transform: scale(1); 
             z-index: 1;
              min-height: 175px;
             img {
              display: block;
              margin:auto;
            }   
            h3 {
              font-size:14px;
              font-weight: 800;
              text-align: center;
              text-transform: uppercase;
              margin-top:0;
            } 
            .glyphicon {
               text-align: center;
               color:#46a9f7;
               display: inline-block;
               margin:auto;
               font-size:14px;
            }    
        } 
        .task:hover,
        .quiz:hover {           
            z-index: 5;
            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1.1);
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1.1);   
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1.1);
            transition: all 200ms ease-in;
            transform: scale(1.1); 
        }    
        .task.done,
        .quiz.done {           
           background:#fff;          
           
          border:none;
          min-height: 175px;
          .ok-sign {
               background: #fff url(../images/icon-tick.png);
                background-repeat: no-repeat;
                background-position: 0 8px;
                display: inline-block;
                width:30px;
                height:38px;
            }  
           
        }
        .task.done:hover,
        .quiz.done:hover {      
            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1.1);
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1.1);   
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1.1);
            transition: all 200ms ease-in;
            transform: scale(1.1); 
        } 
       }
         
     } 
   }  
   .quizzes {
     ul {
       
       list-style:none;   
           li {
            float:left;
            width:16%;
             min-height: 192px;
            .quiz {
              .imagecontainer {
                 min-height: 95px;   
              }  
              h3 {
               text-align: left;
               margin-top:10px;
               margin-right:0px;
               font-size:16px;
               padding-bottom: 6px;
               border-bottom: 1px solid #e1e1e1;
               text-align:center;
              }  
              h3:first-child {              
               
              }   
              p {
               text-align:center;
               font-size:11px;
              }      
            }    
            .quiz.done { 
            
             min-height: 198px;
             h3 {
               text-align: center;
               margin-top:0px;
               font-size:22px;
               border:none;
             }   
             h3:first-child {
               text-align: center;
               margin-top:20px;
               font-size:22px;
             }  
            }    
            
          }
      }
    }  
    .quizwrap {        
        overflow: hidden;        
        width:69.4%;
        margin:auto;
        // for animation
        -webkit-animation-duration: 1s;
        -webkit-animation-delay: 0.5s;
        -moz-animation-duration: 1s;
        -moz-animation-delay: 0.5s;
        -ms-animation-duration: 1s;
        -ms-animation-delay: 0.5s;
        -o-animation-duration: 1s;
        -o-animation-delay: 0.5s;
        animation-duration: 1s;
        animation-delay: 0.5s;
        .quizimage {
          float:left;   
        }   
        .quizcontainer {
          float:left;
          background:#fff;
          padding:0px 30px;
           width:480px;  
           min-height: 444px;
          h2 {
            font-weight: 800;
            font-size:40px;
            padding-bottom: 10px;
            display: inline-block;
            padding-right: 20px;
            border-bottom: 1px solid #e6e6e6;
          }  
          .quizdetails {           
            p {
             font-weight: 800;
             font-size:15px;  
             padding-right: 20px;
             padding-bottom: 10px;
             border-bottom: 1px solid #e6e6e6;
            }                
          }  
          .quiztimer {
              text-align: center;   
              font-size: 55px;
              font-weight: 300;              
             }     
           }  
           p.button {     
                text-align: left;
                display: inline-block;
                float:left;
                 margin-bottom:30px;
                 padding-top:0;
                input.btn {
                 display: inline-block;    
                 width:140px;  
                 text-transform: uppercase;
                 padding:9px 12px;
                }    
          }   
          p.skip { 
               text-align: right;     
               float:right;
               margin-top:30px;
               a {
                 background:url(../images/icon-skip.png);
                 background-repeat: no-repeat;
                 background-position: right;
                 padding-right: 20px;
                 display: inline-block;
                 font-size: 14px;
                 color:#282828;
                 font-weight: bold;                 
               }  
               a:hover {
                 text-decoration: none;   
               }   
          }
          .results {
             .resulticon {
                padding-top:40px;  
                text-align: center;
                padding-left:80px;
                img {
                    margin:auto;
                    display: inline-block;;
                }    
             } 
             .correctorwrong {
               text-align: center;     
               p.correct {
                 margin:auto;
                 font-size:38px;
                 color:#4bd341;
                 background:url(../images/icon-correct-green.png);
                 background-repeat: no-repeat;
                 background-position: left;
                 padding-left:60px;
                 display: inline-block;
               } 
               p.wrong {
                 margin:auto;
                 font-size:38px;
                 color:#ff0000;
                 background:url(../images/icon-wrong-red.png);
                 background-repeat: no-repeat;
                 background-position: left;
                 padding-left:60px;
                 display: inline-block;
               } 
             }
             .answertoquestion {
                 p {
                   font-size:12px;
                   padding-top:10px;
                 }     
              }
              p.button {
                margin-top:30px; 
                text-align:center;
                display: block;
                float:none;
              }      
          }      
   }   
   .box {
     background:#fff;  
     padding:0px 0px;
     overflow:hidden;
     min-height: 267px;
     .todaystaskimage {
       margin:auto;
       padding-right:0;
       img {
          display: block;
          margin:auto;
       }
     }
     ul {
       list-style:none;
       margin:15px 5px 0 5px;
       padding:0;
       li {        
        padding:10px 10px 10px 5px;      
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;        
         margin: 8px 8px;
        background:#fff;
        span {
          margin-right:5px;   
          margin-left:5px;
        }    
       }  
       li:last-child {    
           margin-bottom:0;
       }
       p {
         padding-top:10px;   
         padding-left:15px;
       }   
     } 
     h2.slogan {
       font-weight: 800;
       font-size:35px;
       text-transform: uppercase;
     }
     .dotodaystask {
       background:#f3f3f3;
       min-height: 270px;
       padding-left:40px;
       p.button {
         text-align:left;   
       }   
     }  
   }   
   
   .box-grey {
       background:@boxcolorgrey;
    }
 

.taskcompletion {
    margin-top:20px;
    position: relative;
    background:#6a4aab;
    margin-left:15px;
   .profileimage {
        position: absolute;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 50%;
        width: 96px;
        height: 96px;        
        border: 3px solid #fff;
        top:52px;
        left:67px;
        z-index: 10000;
       img {
        display: inline;
        margin: 0 auto;
        height: 100%;
      } 
      .photo-overlay {
         position: absolute;
         top:0;
         right:0;
         left:0;
         bottom:0;
         background: rgba(0,0,0, .5);
         display:none;
         a {
           color:#ffff;
           font-size: 30px;
           position: absolute;
           top:25px;
           left:30px;
           .fa {
            color:#fff;   
           }  
         }     
      }  
   }
   .profileimage:hover  .photo-overlay { display:block}
   
   
   .progresslabel {
      position:absolute;
      top:15px;
      left:20px;
      color:#fff;
      width:220px;
      img {
        position: absolute;
        left:40px;
        top:5px;
      } 
      span.star {
        position: absolute;
        left:140px;        
        width:24px;
        height:24px;
        
      } 
      span {
        em {
          font-style: normal;   
        }    
      }      
   } 
   
   .charts-container {
      margin-top:20px;  
      margin-left:20px;
   } 
   h3 {
     color:#fff;
     text-align:center;
     margin:0;
     padding:0 0 10px 0;
     font-size:23px;
     text-transform: uppercase;
   }  
   .level {
     text-align: center;
     padding-bottom: 20px;
     span.level {
       background:#fff;  
       padding:0 10px;
       margin-right:15px;
       -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
     }     
   }  
   span.glyphicon {
       color:#ff0707;
       margin-left:10px;
       font-size:18px;       
   } 
}   

.currentmission {
  background:#fff;
  margin-top:20px;
  padding-right:0;
  width:62.8%;
  div.mission {
    width:60%;
    float:left;
    border-right:1px solid #eeeeee;
    padding-right:20px;
    margin-top:20px;
    margin-bottom:20px;
    h3 {
      text-transform: uppercase;
      font-size:20px;
      margin-left:10px;
    } 
    .progress {
        border-radius:0;
        margin-left:10px;
        .progress-bar {         
         background-color:#5a4484;
         span {
           i {
             font-style: normal;   
           }   
         }     
         
      }    
    }    
    
  } 
  .points {
     h2 {
      font-size:72px;
      text-align: center;
      font-weight: 800;
      span {
        font-weight: 300;
        font-size:20px;
        display: block;
        text-transform: uppercase;
      } 
     }     
  } 
  .slider {
      margin-top:20px;
    border-top:1px solid #f7f7f7;
    margin-right:20px;
    margin-bottom: 32px;
    ul {
      li {
        img {
          cursor: pointer;   
        }    
      }  
    }    
  }      
}    

.newsfeed {
  background:#fff;
  margin-top:2px;
  padding:20px 10px 20px 20px;
  h2 {
     margin-top:-5px;
     float:left;
     display: inline-block;
     font-weight: 800;
     font-size: 30px;
     text-transform: uppercase;
     margin-bottom: 10px;
  }  
  ul {
    display: inline-block;
    list-style: none;
    margin-top:0px;    
    overflow: hidden;
    margin-bottom: 10px;
    li {
      float:left;
      padding-left:12px;
      padding-right:13px;
      border-right:1px solid #f4f4f4;
      margin:0;
    } 
    li:last-child {     
      border:none;
    } 
    li.icon1 {
        a {
          background:url(../images/icon-run.png);
          background-repeat: no-repeat;
          background-position: 0 0px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a:hover {
          background:url(../images/icon-run.png);
          background-repeat: no-repeat;
          background-position: 0 -27px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a.active {
          background:url(../images/icon-run.png);
          background-repeat: no-repeat;
          background-position: 0 -27px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
      }  
      li.icon2 {
        a {
          background:url(../images/icon-list.png);
          background-repeat: no-repeat;
          background-position: 0 -2px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        } 
        a.active {
          background:url(../images/icon-list.png);
          background-repeat: no-repeat;
          background-position: 0 -29px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a:hover {
          background:url(../images/icon-list.png);
          background-repeat: no-repeat;
          background-position: 0 -29px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
      }  
      li.icon3 {
        a {
          background:url(../images/icon-feed3.png);
          background-repeat: no-repeat;
          background-position: 0 2px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a.active {
          background:url(../images/icon-feed3.png);
          background-repeat: no-repeat;
          background-position: 0 -25px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a:hover {
          background:url(../images/icon-feed3.png);
          background-repeat: no-repeat;
          background-position: 0 -25px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
      }  
      li.icon4 {
        a {
          background:url(../images/icon-feed4.png);
          background-repeat: no-repeat;
          background-position: 0 0px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a.active {
          background:url(../images/icon-feed4.png);
          background-repeat: no-repeat;
          background-position: 0 -25px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a:hover {
          background:url(../images/icon-feed4.png);
          background-repeat: no-repeat;
          background-position: 0 -25px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
      }  
      li.icon5 {
        a {
          background:url(../images/icon-ribbion.png);
          background-repeat: no-repeat;
          background-position: 0 0px;
          display: block;
          width:26px;
          height:25px;
          text-indent: -999999px;
        }  
        a.active {
          background:url(../images/icon-ribbion.png);
          background-repeat: no-repeat;
          background-position: 0 -24px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
        a:hover {
          background:url(../images/icon-ribbion.png);
          background-repeat: no-repeat;
          background-position: 0 -24px;
          display: block;
          width:26px;
          height:27px;
          text-indent: -999999px;
        }  
      }  
      
  }
  .feed {
     height:438px; 
     clear:both;
     margin-bottom: 20px;
     border-top:1px solid #e4e4e4;
     padding-top:10px;
     ul {
       margin-left:0;
       padding-left:0;
       margin-right:0;
       padding-right:0;
       display: block;
       li {
          float:none;
          width:100%;
          border:none;
          clear:both;
          padding-left:0;
          background:url(../images/hline.png);
          background-repeat: no-repeat;
          background-position:  bottom;
          overflow: hidden;
           padding-top: 0px;
          padding-bottom: 15px;
          margin-bottom: 20px;
          .feedimage {
                float:left;
                margin-right: 0px;
                margin-top:13px;
                overflow: hidden;
                border-radius: 50%;
                width: 40px;
                height: 40px;        
                border: 2px solid #fff;
                
               img {
                display: inline;
                margin: 0 auto;
                height: 100%;
              } 
      
           }
     .feedtitle {
        float:left;
        width:68px;
        margin-left:10px;
        background:url(../images/feedline.png);
        background-repeat: no-repeat;
        background-position: right;
        padding-right:5px;
        margin-top:13px;
        h3 {
          padding:0;
          margin:5px 0 5px 0;
          font-size:14px; 
        }   
        span {
             background:#5a4484;  
           padding:0 10px;
           -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            color:#fff;
            font-size: 11px;
          }   
     }
     .taskstatus {
        float:left;
        width:128px;
        padding-right:10px;
        margin-left:10px; 
        background:url(../images/feedline.png);
        background-repeat: no-repeat;
        background-position: right;
        padding-top:13px;
        p {
         font-size: 11px;   
        }    
     } 
     .taskpicture {
        float:left;
        width:90px;
        margin-right:5px; 
        background:url(../images/feedline.png);
        background-repeat: no-repeat;
        background-position: 100% 38%; 
        img {
          width:88px;
          height:79px;
        }    
     }  
     .time {
        float:left;
        width:80px;
        margin-left:10px; 
        font-size:11px;
        text-align: center;
        margin-top:10px;
        span.icon {
           display: block;
           margin-top:5px;
           color:#ff0707;
           font-size: 25px;
           .fa {
             color:#ff0707;  
           }
        }    
       } 
       }   
     } 
     
  } 
   
} 
.leaderboard {   
   overflow: hidden;   
 .profile {
        width:288px;
        float:left;
        background:#6b4bad;
        text-align: center; 
        padding-bottom: 30px;
           .profileimage {        
                margin: 80px auto auto auto;
                 overflow: hidden;
                border-radius: 50%;
                width: 150px;
                height: 150px;        
                border: 5px solid #fff;        
                   img {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                  } 
           }
           h3 {
            color:#fff;
            font-size: 22px;
            text-transform: uppercase;
            margin:8px 0 0 0;
            padding:0;
           } 
           .level {
             margin-top:10px;  
             span {
                     background:#fcfbfd;  
                   padding:0 10px 2px 10px;
                   -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    border-radius: 8px;
                    color:#272727;
                    font-size: 11px;
                    font-weight: bold;
                  }      
           }  
           .points {
             p {
               color:#fff;
               font-size:52px;
               font-weight: bold;
               margin-top:10px;       
             }     
           } 
           .ribbion {
                color:#fff;
               font-size:17px;
               background:url(../images/profile-ribbion.png);
               background-repeat: no-repeat;
               padding-left:20px;   
               display: inline-block;
           }   
    } 
    .toplist {
        float:left;
        width:440px;
        background:#fff;
        min-height: 448px;
        .toplistheader {
          overflow: hidden;
           border-bottom: 1px solid #e2e2e2;
          padding-bottom: 0px;
          margin-bottom: 5px;
        }    
        h2 {
          font-size:30px;
          font-weight: 800;
          text-transform: uppercase;
          margin:10px 20px 20px 20px;
         
          float:left;
          width:150px;
        }  
        table {
          width:90%;
          margin:0 20px;
          tr {
             margin-bottom: 5px;
             -moz-box-shadow: 0px 2px 2px #f6f6f6;
             -webkit-box-shadow: 0px 2px 2px #f6f6f6;
             box-shadow: 0px 2px 2px #f6f6f6;  
             border-radius: 10px 10px 10px 10px;
            -moz-border-radius: 10px 10px 10px 10px;
            -webkit-border-radius: 10px 10px 10px 10px;
            border: 0px solid #000000;
            td:last-child {background:none; }
            td {
              padding:8px 10px 8px 10px; 
              font-size:14px;
              background: url(../images/feedline.png);
                background-repeat: no-repeat;
                background-position: right;
              span.rank {
                margin-left:8px;
                margin-right:8px;
              } 
              span.level {
                 background:#303f9f;  
                 padding:0 10px 2px 10px;
                 -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
                color:#fff;
                font-size: 11px;
                font-weight: bold;   
              }   
              span.level.lightblue {
                 background:#0042e7;                    
              }  
              span.level.green {
                 background:#00b91f;                    
              }  
               span.level.green {
                 background:#fc8d1f;                    
              }  
              span.glyphicon {
                margin-right:8px;   
              }      
            }    
          }      
        }    
    }    
 }  
 
 /*story board */
 .storyboard {
     position: relative;
     .excerpts {
       padding-right:10px;   
     }     
    .col-md-4 { padding-right:5px; }
    min-height: 500px;
   article {
     background:#fff;
     padding:10px;
     overflow: hidden;
     margin-bottom: 20px;
     .articleimage {
       float:left;
       width:48%;
       img {
         max-width: 100%;
         height: auto;   
       }  
     } 
     .story {
       float:right;
       width:49%;
       margin-left:3%;
       .share {
         float:right;
         ul {
           list-style:none;
           li {
            float:left;
            margin:0 3px;
            
           
            a {
              display:block;
              color:#5a4484;
              
            }    
           } 
           li.comment {            
           
           }
            li.forward {             
           
           }
         }     
       } 
       h3 {
        clear:both;
        font-weight: 800;
        font-size:20px;
        padding:0;
        margin:0;
        a {
         color:#4d4d4d;   
        }    
       } 
       p.meta {
         border-bottom: 1px solid #f0f0f0;
         padding-bottom: 10px;
         padding-top: 5px;
       }   
       p {
         line-height: 15px;         
       }  
       p.readmore {
        text-align: right;
        background:url(../images/readmore-arrow.png);
        background-repeat: no-repeat;
        background-position: right;
        padding-right: 10px;
        margin-top:20px;
       }       
        
     } 
   }  
   .fullarticle {
     padding-left:15px;
     padding-right:5px;
     position: absolute;
    width: 1050px;
     span.closepop {
       position: absolute;
       right:-15%;
       cursor: pointer;
       top:1px;
       
     }     
     article {
       padding:0;
       margin-left:10%;
       margin-right:10%;
      
       min-width: 1050px;
       .articleimage {
         float:none;
         width:100%;
       }
       .story {
         clear:both;
         float:none;
         width:100%;
         padding-bottom: 30px;
         .share {
            margin-right:50px;
            margin-top:20px;
         } 
         .content {
           height:200px;
           margin-right:40px;
            
           h3 {
              clear:none;
              padding-top:20px;
             }     
         }     
           
       }   
     }     
      
   }   
 } 
 
 /*Gamevault*/
 .gamevault {
     position: relative;
    .col-md-4 { padding-right:5px; }
    .gamevaultcontent {
      background:#fff;   
      padding:10px 25px;
      .imagewrap {
        padding-top:20px;
        min-height: 192px;
        img {
            display: block;
            margin:auto;
          }      
      }
      h2 {
        font-weight: 800;
        font-size:34px;
        text-transform: uppercase;
        padding-bottom: 10px;
        margin-right: 20px;
        border-bottom: 1px solid #e8e8e8;
        a {
          color:#4d4d4d;   
        }    
      }      
      
    }    
 }
 
 /*Gamevault*/
 .knowmore {
     position: relative;
    .col-md-3 { padding-right:5px; width:30%;}
    .knowmorecontent {
      background:#fff;   
      padding:10px 25px;
      .imagewrap {
        padding-top:20px;
        min-height: 192px;
        img {
            display: block;
            margin:auto;
          }      
      }
      h2 {
        font-weight: 800;
        font-size:34px;
        text-transform: uppercase;
        padding-bottom: 10px;
        margin-right: 20px;
        border-bottom: 1px solid #e8e8e8;
        a {
          color:#4d4d4d;   
        }    
      }      
      
    }    
 }
 
 /*survey*/
 .survey {
     .col-md-7 {
       margin:auto;   
       float:none;
       .surverycontents {
         background:#fff;
         height:460px;
         padding: 6px;
         form {
           width:80%;
           margin:auto;
           .fieldrow {
             overflow: hidden;
             border-bottom: 1px solid #e4e4e4;
           }
            .fieldrow.submit {
              border-bottom: 0px;  
            }
         }     
         h2 {
           font-weight: 800;
           font-size: 18px;
         }  
          div.radio {        
           padding:10px;          
         } 
         div.radio:hover {
           background:#49366f;
           padding:10px;
           color:#fff;
         }    
          div.radio.checked {
           background:#49366f;
           padding:10px;
           color:#fff;
         }     
       }   
     }     
 }
 
  /*Fitter Choice*/
 .fitterchoice {
    margin-left:13%;    
    overflow: hidden;
    margin-top:30px;
    .choice {
      float:left;
      width:50%;
      background:#fff;
      padding-bottom: 20px;
      min-height: 320px;
      h3 {
        font-weight: 800;
        font-size:26px;
        text-transform: uppercase;
        padding:5px 20px 10px 0;
        margin-left:20px;
        margin-right: 30px;
        border-bottom: 1px solid #e8e8e8;
      } 
      .choiceimage {
         margin:40px 0;   
         text-align: center;   
         img {
           display: inline-block;
           margin:auto;
         }     
      }  
      .previousnext {
        span {
          display: block;
          float:left;
          margin-left:20px;
          margin-right:20px;
        }
        span.glyphicon-arrow-right {
          float:right;   
        }    
      }  
    }  
    .choiceanswer {
      float:left;
      width:45%;
      background:#5a4484;
      min-height: 320px;
      h3 {
        margin-left:20px;
        color:#fff;
        font-weight: 800;
        margin-right: 20px;
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }  
      .radio {
         margin-left:20px;
         color:#fff;
      } 
      .button {
          float:right;
          margin-right:20px;
          background:#ffffff;
          color:#282828;
          
          .btn-default {
                color:#282828;
                background-color: #ffffff;
                border-color: #ffffff;
                border: none;
                font-size:11px;
                width:100px;
                font-weight: bold;
            }
      } 
    } 
 
 }
 
 .fitterchoiceslide {
   display:none;   
 }     
 
 .graph {    
    text-align: center; 
    width:800px;
    margin:40px auto;
   img {
     display: inline-block;
     margin:auto;
   }   
 }     
 
 .fiterchoicewrap {
    .tasks {
        h2 {
         text-align: left;
         color:#fff;
         font-size:20px;
         font-weight: 800;
         margin-left:35px;
         margin-top:0;
         padding-bottom: 10px;
        }    
        ul li .task.done {
        
          height:158px;
          h3:first-child {
            margin-top:20px;   
          }      
        }
            
    }   
 }  
 
 /* FAQ */

  
  .faq {
    .col-md-5,.col-md-7 { background:#fff }
    overflow:hidden;
    margin-right:80px;
    background:#fff;
    h3 {
      font-weight: 800;
      font-size:40px;
      padding:20px 30px 10px 0px;
      border-bottom: 1px solid #e1e1e1;
      display: inline-block;
    }
    .scrollbutton {
        margin-left:97px;
        margin-top:60px;
        margin-bottom:60px;
       #scroll-down {
         background:url(../images/scrol-down-arrow.png);
         background-repeat: no-repeat;
         width:58px;
         height:18px;
         margin-right:50px;
         display: inline-block;
         text-indent: -9999999px;
      } 
      #scroll-up {
         background:url(../images/scrol-up-arrow.png);
         background-repeat: no-repeat;
         width:58px;
         height:18px;
         display: inline-block;
         text-indent: -9999999px;
      }    
    }    
     
    .questions {
      margin-left:0px;
      border-right:1px solid #efefef;
      div#scroll {
        width: 300px;
        height: 188px;
        overflow: hidden;
        padding: 4px;
        margin-bottom: 20px;
        h4 {
            font-size:14px;
            padding:10px;
            margin:0;
            font-weight: bold;
            a {
              color:#282828;   
            }    
        }  
        h4:hover {
            background:#5a4484;
            font-size:14px;
            padding:10px;
            a {
              color:#fff;   
            }    
        }  
      }
    }
    .content {
        height:400px;
        margin-top:40px;
    }    
    .content-container {
       margin:0px 0px 0 0px;
       height:360px;
       position: relative;
       div {
        display:none;  
        margin-top:20px;
         position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
       } 
       div:first-child {
        display:block;   
       } 
    }    
  }
  
  
   /* Query */
    .query {
        width:100%;
        .col-md-8 {
            background:#5a4484;
            margin:auto;
            float:none !important;
            overflow: hidden;
        }    
      .queryform {
         width:70%;
         float:left;
          background:#fff;
         h3 {
          font-weight: 800;
          font-size:30px;
          padding:20px 0 20px 30px;    
          text-transform: uppercase;
        } 
        .form-group {
            clear:both; 
            margin-left:30px;
            margin-right: 40px;
            input.form-control {
                width:400px;
                border-radius:0;
            }
            textarea.form-control {
                border-radius:0;
            }    
          } 
          .form-group.submit {
              text-align: right;
          } 
      }
      .queryon {
         background:#5a4484;
         float:left;
         width:30%;
         h3 {
          font-weight: 800;
          font-size:30px;
          padding:20px 0 20px 20px;   
          color:#fff;
          text-transform: uppercase;
        } 
        .radio {
          padding:5px 0 0 20px;
          color:#fff;
          font-size: 16px;
          text-transform: uppercase;
        }    
      }      
    }
    
     /* Gallery  */  
     .gallerysubnav {
       margin-top:20px;
       margin-right:0px;     
       float:right;
       ul {
         list-style:none;
         li {
           float:left;
           padding:8px 20px;
           margin:0 8px; 
           font-weight: 800;
           font-size:18px;
           text-transform: uppercase;
           background:#48366e;
           -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
           a {
             color:#fff;   
           }   
         }     
       }   
     } 
     
     .gallerygrid {
         clear:both;
         margin-left:15px;
         #photos {
              opacity: .88;
              padding:0;
                img {
                          width: 15%;
                           -moz-transition: all 0.3s;
                          -webkit-transition: all 0.3s;
                          transition: all 0.3s;
                        } 
            }
            .photo {
                position: relative;
                div.caption {
                    position: absolute;
                    bottom:0;
                    left:0;
                    right:0;
                    background:#5c4390;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.2s ease-in-out;
                    padding:10px 10px 0 10px;
                    color:#fff;
                    a {
                      color:#fff;   
                    }
                     a:hover,a:focus {
                      color:#fff;   
                    }   
                    h3 {
                      padding:0;
                      margin:0;
                      font-size:14px;
                      font-weight: bold;
                      position: relative;
                      span.likes {
                         position: absolute; 
                         right:20px;
                      }      
                    }    
                    p {
                      padding:10px 0;
                      margin:0;
                      font-size:12px;
                      font-weight: bold;  
                    }    
                    
                }    
            }    
     }  
     
     .galleryalbum {  
        padding-right: 10px; 
       .col-md-4 {
           padding-right: 5px;
      }
       .album {
         background: #fff;
        padding: 10px;
        overflow: hidden;
        margin-bottom: 20px;
         .albumcover {
           float: left;
            width: 58%;
            img {
               max-width: 100%;
               height: auto;   
            }    
         } 
         .albumdetails {
            float: right;
            width: 39%;
            margin-left: 3%; 
            h3 {
                clear: both;
                font-weight: 800;
                font-size: 20px;
                padding: 0;
                margin: 0;
                a {
                  color:#4d4d4d;   
                }    
            }  
            p.date {
             border-bottom: 1px solid #f0f0f0;
             padding-bottom: 10px;
             padding-top: 5px;   
            }  
            p {
             line-height: 15px;   
            }  
            p.readmore {
                text-align: right;
                background: url(../images/readmore-arrow.png);
                background-repeat: no-repeat;
                background-position: right;
                padding-right: 10px;
                margin-top: 20px;
            }
         }     
       }   
     }     
 }  
 
  #photos .photo:hover img {
      -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  } 
 
 #photos .photo:hover div.caption {
   
  visibility: visible;
  opacity: .5;
 }  
 
 #overlaymenu {
  background: rgba(0,0,0, .8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  text-align: center;
  z-index: 100000;
}

#overlay {
  background: rgba(0,0,0, .8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  text-align: center;
  z-index: 100000;
}

#overlay img {
  margin: 10% auto 0;
  max-width: 650px;
  border-radius: 5px;
}

#overlay .addpic {
  margin: 10% auto 0;
  max-width: 300px; 
  display:none; 
  span.closebtn {
    display: block;
    width:40px;
    height:40px;
    background:url(../images/icon-closepopup.png);
    background-repeat: no-repeat;
    position: absolute;
    margin-left:300px;
    margin-top:0px;
    cursor: pointer;
  }      
  .addpic-content {
     background:#fff; 
     padding:20px;
     .image-upload > input
        {
            display: none;
        }
        
        .image-upload img
        {
            display: block !important;
            width: 80px;
            cursor: pointer;
            margin-bottom: 10px;
        }
     h3 {
       padding:0 0 0px 0;
       margin:0 0 10px 0;
       font-size: 20px;
       font-weight: 800;
       
     }     
     .form-control.file {
       border:none; 
       border-radius:0;
       box-shadow: none;
     }  
     .form-control {
       border-radius:0;
     } 
  }      
} 

#overlay .addvideo {
  margin: 8% auto 0;
  max-width: 850px; 
  display:none; 
  span.closebtn {
    display: block;
    width:40px;
    height:40px;
    background:url(../images/icon-closepopup.png);
    background-repeat: no-repeat;
    position: absolute;
    margin-left:850px;
    margin-top:1px;
    cursor: pointer;
  }      
  .addvideo-content {
     background:transparent; 
     padding:0px;
     
  }      
} 



.loaderbadge,
.loader{
    position:fixed; /* keeps the lightbox window in the current viewport */
    top:0; 
    left:0; 
    width:100%; 
    height:100%; 
    background:rgba(0, 0, 0, 0.8);; 
    text-align:center;
    z-index: 10000;
    .popcontent {
        position: relative;
        top:25%;
        img {
          display: inline-block;  
          z-index: 100000;
          
        } 
        img.sliderpopimages {
          width:195px;
          height:195px;
          
        } 
         img.badgethree,
         img.badgetwo {
            display:none; 
         }
         .badgecontent {
             margin:auto;    
             width:272px;
             background:#fff;
             margin-top:-100px;
             padding-top:100px;
             position: relative;
             z-index: -10;
             padding-bottom: 10px;           
             
              h3 {
               font-size:22px;              
               padding-bottom: 10px;
               margin:10px 20px;
              color:#a2cc35;
              text-transform: uppercase;
             }  
             h2 {
               font-size:30px;
               font-weight: 800;
               padding-bottom: 10px;
               margin:20px 20px;
               color:#4d4d4d;
               border-bottom: 1px solid #afa2c9;
             }  
             p {
               font-size:13px;  
                width:272px;              
               padding:0px 20px 10px 20px;
               margin:10px 0px;
              
               color:#4d4d4d;
             } 
             .badgetwo,
             .badgethree,
            
             { display:none; }
             img.pnext {
               position: absolute;
               right:-27px;
             }  
             img.pprev {
               position: absolute;
               left:-27px;
             }  
            }    
    } 
       
}

.loader.privacy {
    display:none;
    .content {
        width:900px;
        background:#fff;
        margin:auto;
        height:500px;
        text-align: left;
        h2 {
         text-align:left;   
        }    
    }    
}    

.loader.message {
    display:none;
   
    .alertmessage {
        -webkit-animation-duration: 2s;
        -webkit-animation-delay: 0.5s;
        -moz-animation-duration: 2s;
        -moz-animation-delay: 0.5s;
        -ms-animation-duration: 1s;
        -ms-animation-delay: 0.5s;
        -o-animation-duration: 2s;
        -o-animation-delay: 0.5s;
        animation-duration: 2s;
        animation-delay: 0.5s;
        position: relative;
         top:40%;
        width:600px;
        background:#fff;
        margin:auto;      
        text-align: left;
        p {
         text-align:center;   
         padding:20px;
         font-weight: bold;
        }    
    }    
}    
    
    .outerwrap {
      header {
          .slideoutmenulink {
              float:left;
              width:30px;
            .glyphicon-menu-hamburger {
              font-size: 20px;
              color:#fff;
              display: inline-block;
              cursor:pointer;
           }
           .glyphicon-remove {
              font-size: 20px;
              color:#fff;
              display: inline-block;
              cursor:pointer;
              display:none;
           } 
            
         }    
      }  
    }    
   
    .slideoutmenu {
        nav {
                width:150px;
                padding:0 0px 0 0px;   
                position: fixed !important;
                z-index: 100000000;
                background:#fff;
                top:0px;
                height:100%;
                margin:0 0 0 -150px;
                -webkit-transition: .5s ease-in;
                -moz-transition: .5s ease-in;
                -o-transition: .5s ease-in;
                -ms-transition: .5s ease-in;
                transition: .5s ease-in;
                overflow: auto;
                  .closeslide {
                    padding-top:20px;
                    padding-left:20px;
                    cursor: pointer;
                    span {
                      font-size: 20px;   
                    }    
                  }  
                
                    ul {
                      margin:10px 0 0 0;  
                      padding:0;
                      list-style:none;
                      li {
                        clear:both;
                        float:none;
                        padding:12px 10px;
                        border-bottom: 1px solid #f8f8f8;
                        margin:0 0px;
                        text-transform: uppercase;
                        text-align: center;
                        font-size:12px;                        
                        a {
                          color:#000;   
                        } 
                        span {
                          .fa {
                             font-size: 5px;   
                             position: relative;
                             top:-10px;
                             left:0px;
                          } 
                        }  
                        span.green {
                           .fa {
                             color:#a5cd38;   
                           } 
                        }
                        span.red {
                           .fa {
                             color:#fe0100;   
                           } 
                        }
                      }  
                      li:hover,
                      li.highlight {   
                         background:#5a4484;  
                        a {
                          color:#fff;  
                         
                        }    
                      }
                      li.profile {
                          border:0;
                          padding-right:0;
                          overflow: hidden;
                          width:100%;
                           .profileimage {
                            
                           float: left;
                           margin-top:0px;
                           margin-bottom: 0px;
                           margin-right: 0px;
                           overflow: hidden;
                           border-radius: 50%;
                           width: 60px;
                           height: 60px;  
                           position: relative;              
                           border:2px solid #fff;
                           img {
                             display: inline;
                             margin: 0 auto;
                             height: 100%;   
                           } 
                        }   
                        span {
                            float:right;
                            clear:none;
                             display:block;
                             margin-top:20px;
                             
                             width:80px;
                           } 
                    } 
                    li.profile:hover {
                        background:transparent;
                    } 
                    
                    }    
            }
            
        .menushow {
            margin: 0;
            box-shadow:0 0 50px 0 rgba(0,0,0,.5);
        }
    } 
    
      .slideoutmenu.stuck {
            nav.menushow {
                
                top:0px;
                height:100%;
                margin:0 0 0 0px;
                -webkit-transition: .5s ease-in;
                -moz-transition: .5s ease-in;
                -o-transition: .5s ease-in;
                -ms-transition: .5s ease-in;
                transition: .5s ease-in;}
      }
    
    /*hamburger menu*/
 
 .carousel-container {
   position: relative;
   margin-top:20px;
   min-height: 500px;
 }     
 
.carousel {
	left: 50.5%;
	position: absolute;
	width: 1000px;
	margin-left: -500px;
	overflow: hidden;
	height: 500px;
	top: -100px;
}

.carousel .item {
	width: 250px;
	height: 450px;
	background: #4c4c4c;
	background-size: 100%;
	vertical-align: middle;
    box-shadow: none !important;
	.carouselcontent {
	    h2 {
	      color:#fff;
	      text-align:center;
	      margin-right:20px;
	      font-weight: 800;
	      font-size: 20px;
	      text-transform: uppercase;
	      span  {
	         display:block;   
	       }   
	    }     
	    .profile {   
        text-align: center; 
        padding-bottom: 30px;
           .profileimage {        
                margin: 0px auto auto auto;
                 overflow: hidden;
                border-radius: 50%;
                width: 120px;
                height: 120px;        
                border: 5px solid #fff;        
                   img {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                  } 
           }
           h3 {
            color:#fff;
            font-size: 20px;
            text-transform: uppercase;
            margin:8px 0 0 0;
            padding:0;
           } 
           .level {
             margin-top:10px;  
             span {
                     background:#fcfbfd;  
                   padding:0 10px 2px 10px;
                   -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    border-radius: 8px;
                    color:#272727;
                    font-size: 11px;
                    font-weight: bold;
                  }      
           }  
           .points {
             p {
               color:#fff;
               font-size:52px;
               font-weight: bold;
               margin-top:10px;       
             }     
           } 
           .ribbion {
                color:#fff;
               font-size:17px;
               background:url(../images/profile-ribbion.png);
               background-repeat: no-repeat;
               padding-left:20px;   
               display: inline-block;
           }   
    } 
	} 
	
}

.carousel .item.active {
	    background:#fff;
	    box-shadow: none !important;
	    .carouselcontent { 
	        h2 {
	          color:#4d4d4d;  
	        }
	        .profile {  
	             h3 {
                  color:#4d4d4d;           
                 } 
               .level {
                     span {
                             background:#594384;                     
                            color:#fff;
                            font-weight: bold;
                          }      
                }  
                .points {
                 p {
                   color:#4d4d4d;                        
                 }     
               } 
               .ribbion {
                    color:#4d4d4d;  
                    background:url(../images/icon-ribbion-black.png);
                    background-repeat: no-repeat;
               }  
	          }
	    }
}   


.controls {
	position: absolute;
	top: 200px;
	left: 20%;
	width:930px;
	margin-left: -75px;
	z-index: 10000;
}

.controls a,
.controlsa:focus, .controls a:hover{
	display: block;
	color:#fff;
	font-size: 30px;
}

.controls .previous {
	position: absolute;
	top:0;
	left:-13px;
}

.controls .next {
	position: absolute;
	top:0;
	right:6px;
}

.not-found {
   background:url(../images/empty.png);
   background-repeat: no-repeat;
   width:658px;
   height:436px;
   margin:30px auto;
}

video{
        background: transparent url("../images/poster.jpg") 50% 50% / cover no-repeat ;
    }
        

footer {
  padding-bottom: 20px;
  ul {
    float:right;
    display: inline-block;
    list-style: none;
    margin-top:30px;
    li {
      float:left;
      color:#fff;     
      font-size:12px;
      border-right:1px solid #fff;
      padding-left:10px;
         padding-right:10px;
              a {
                color:#fff;                  
              } 
    }   
    li:last-child {
          border:none;
      }   
  } 
}    